import React, { useEffect, useState, useCallback } from "react";
import PropTypes from 'prop-types';
import { Grid, TextField, FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import clsx from "clsx";
import { useStyles } from "./styles.js";
import { useSelector } from "react-redux";
import { useEnqueueSnackbar } from "../../../../../hooks/useEnqueueSnackbar";
import useCurrentFacility from "../../../../../hooks/useCurrentFacility";
import apiClient from "../../../../../auth/apiClient";
import RateService from "../../../../../services/RateService";

import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import _ from "lodash";

export const cubicTerminalSettingsSchema = Yup.object().shape({
	ipAddress: Yup.string()
		.matches(
			/^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
			"Must be valid IP address"
		),
	port: Yup.number()
		.typeError("Must be a number")
		.integer("Must be a whole number")
		.positive("Must be positive value")
		.moreThan(0, "Must be greater than 0")
});

const rateService = new RateService(apiClient);

const CubicTerminalSettings = ({ peripheral, updatePeripheralSettings }) => {
	const classes = useStyles();

	const settingsClone = _.cloneDeep(peripheral?.settings);

	const { control, watch, getValues, setValue, formState: { errors } } = useForm({
		mode: 'onChange',
		reValidateMode: 'onChange',
		resolver: yupResolver(cubicTerminalSettingsSchema),
		defaultValues: {
			ipAddress: settingsClone?.ipAddress ?? undefined,
			port: settingsClone?.port ?? undefined,
			hostID: settingsClone?.hostID ?? undefined,
			rateID: settingsClone?.rateID ?? '',
		},
	});

	const enqueueSnackbar = useEnqueueSnackbar();

	const { facilityID } = useCurrentFacility();
	const scopeAwareFacilityID = useSelector((state) => state.entityScope?.facilityGroupId || facilityID);
	const [rates, setRates] = useState([]);

	useEffect(() => {
		getAvailableRates();
	}, [scopeAwareFacilityID]);

	const getAvailableRates = useCallback(async () => {
		try {
			const result = await rateService.getAvailableRates(scopeAwareFacilityID);
			if (result?.data && Array.isArray(result.data)) {
				setRates(result.data);
			} else {
				throw new Error("Invalid data format");
			}
		} catch (err) {
			enqueueSnackbar("Unable to retrieve rates for this entity", {
				variant: "error",
				tag: "FailedToFindRatesForEntity",
			});
		}
	}, [scopeAwareFacilityID]);

	const onRiderRateChange = (settingName, value) => {
		setValue(settingName, value);
	}

	useEffect(() => {
		const subscription = watch(() => updatePeripheralSettings(getValues(), peripheral.peripheralID));

		return () => subscription.unsubscribe();
	}, [watch]);

	return (
		<Grid container >
			<Grid item xs={12} lg={12} className={clsx([classes.drawerItems])}>
				<Controller
					name="ipAddress"
					control={control}
					render={({ field }) => (
						<TextField
							{...field}
							id="ipAddress"
							label="IP Address"
							error={Boolean(errors.ipAddress)}
							helperText={errors.ipAddress?.message}
							variant="outlined"
							fullWidth
							InputProps={{
								inputProps: {
									"data-testid": "ipAddress"
								}
							}}
						/>
					)}
				/>
			</Grid>
			<Grid item xs={12} lg={12} className={clsx([classes.drawerItems])}>
				<Controller
					name="port"
					control={control}
					render={({ field }) => (
						<TextField
							{...field}
							id="port"
							label="Port"
							error={Boolean(errors.port)}
							helperText={errors.port?.message}
							variant="outlined"
							fullWidth
							InputProps={{
								inputProps: {
									"data-testid": "port"
								}
							}}
						/>
					)}
				/>
			</Grid>
			<Grid item xs={12} lg={12} className={clsx([classes.drawerItems])}>
				<Controller
					name="hostID"
					control={control}
					render={({ field }) => (
						<TextField
							{...field}
							id="hostID"
							label="Host ID"
							error={Boolean(errors.hostID)}
							helperText={errors.hostID?.message}
							variant="outlined"
							fullWidth
							InputProps={{
								inputProps: {
									"data-testid": "hostID"
								}
							}}
						/>
					)}
				/>
			</Grid>
			<Grid item xs={12} lg={12} className={clsx([classes.drawerItems])}>
				<Controller
					name="rateID"
					control={control}
					render={({ field }) => (
						<FormControl fullWidth variant="outlined">
							<InputLabel id="riderRateLabel" className={clsx("dropdown-label")}>Rider Rate</InputLabel>
							<Select
								{...field}
								displayEmpty
								data-id="rider-rate-dropdown"
								className={clsx("rider-rate-dropdown")}
								variant="outlined"
								fullWidth
								labelId="riderRateLabel"
								label="Rider Rate"
								inputProps={{
									id: "rider-rate-dropdown",
									"data-testid": "rider-rate-dropdown"
								}}
								onChange={(e) =>
									onRiderRateChange("rateID", e.target.value)
								}
							>
								{rates.map((row) => {
									return (
										<MenuItem key={row.rateID} value={row.rateID} id={row.name}>
											{row.name}
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>
					)} />
			</Grid>
		</Grid>
	)
};

CubicTerminalSettings.defaultProps = {
	peripheral: {},
	updatePeripheralSettings: () => { }
};

CubicTerminalSettings.propTypes = {
	peripheral: PropTypes.object,
	updatePeripheralSettings: PropTypes.func
};

export default CubicTerminalSettings