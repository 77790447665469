import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/* Components */
import {
	Box,
	Grid,
	Button,
	Divider,
	Typography,
	FormControl,
	MenuItem,
	TextField,
	Tooltip
} from "@material-ui/core";
import DoorStrikeSettings from "./PeripheralConfiguationFormOptions/DoorStrikeSettings";
import BNRConfiguration from "./PeripheralConfiguationFormOptions/BNR/BNRConfiguration";
import BNRAccepted from "./PeripheralConfiguationFormOptions/BNR/BNRAccepted";
import GateSettings from "./PeripheralConfiguationFormOptions/GateSettings";
import ProxReaderSettings from "./PeripheralConfiguationFormOptions/ProxReaderSettings";
import GatewayDropDown from "../../Gateway/GatewayDropDown";
import CubicTerminalSettings from "./PeripheralConfiguationFormOptions/CubicTerminalSettings/CubicTerminalSettings";

/* Form */
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

/* State */
import useHasPermissions from "../../../hooks/useHasPermissions";
import { useFeatureFlag } from "../../../hooks/useFeatureFlags";

/* Utilities */
import _ from "lodash";
import clsx from "clsx";
import * as Yup from "yup";
import { isUndefined } from "lodash";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";
import { useGatewayContext } from "../../../providers/CreditCardGatewayProvider";

/* Style */
import { useStyles } from "./styles";

/* Enum */
import { DEVICE_MODE, LOOP_STATUS, PERIPHERAL_TYPES } from "../../../constants/index";

/* Service */
import apiClient from "../../../auth/apiClient";
import DeviceService from "../../../services/DeviceService";

export const peripheralSchema = Yup.object().shape({
	peripheralTypeID: Yup.number().required("Required"),
	model: Yup.string().required("Required"),
	serial: Yup.string().required("Required"),
	ccdeviceid: Yup.string().max(16, "Cannot exceed 16 characters"),
	settings: Yup.lazy((value) => {
		if (value.transactionTimeout !== undefined || value.creditCardGateway !== undefined) {
			return Yup.object().shape({
				creditCardGateway: Yup.string().required("Required"),
				transactionTimeout: Yup.number()
				.typeError('Timeout must be a number from 185 to 300')
				.max(300, "Timeout cannot be greater than 300 seconds")
				.min(185, "Timeout cannot be less than 185 seconds")
			});
		}	
		return Yup.mixed().notRequired();
	}),
});
const deviceService = new DeviceService(apiClient);

const defaultPeripheralDetail = {
    model: "",
    name: "",
    peripheralID: undefined,
    peripheralTypeID: undefined,
    serial: "",
    settings: {}
  };

const PeripheralConfigurationForm = ({
	handleSave,
	handleEdit,
	handleDelete,
	handleCancel,
	peripheral,
	isCCTerminalConfigured,
	isCubicTerminalConfigured,
	deviceSettings
}) => {

	const enqueueSnackbar = useEnqueueSnackbar();
	const classes = useStyles();
	const { hasPermissions } = useHasPermissions();
	const canConfigureCashUnit = hasPermissions(["overview.devices.configurecash"]);
	const isCubicTerminalEnabled = useFeatureFlag('Cubic Terminal');
	const { readerConfiguration: isReaderConfigurationEnabled} = useFlags();
	const [selectedPeripheralID, setSelectedPeripheralID] = useState(undefined);
	const [gateways, setGateways] = useState([]);
	const { functions: {
		getCreditCardGateways
	  } } = useGatewayContext();
	
	const {
		setValue,
		handleSubmit,
		watch,
		control,
		reset,
		formState: { errors, isSubmitting, isDirty, isSubmitSuccessful },
	} = useForm({
		defaultValues: { ...peripheral },
		resolver: yupResolver(peripheralSchema),
		shouldUnregister: false,
		mode: 'onChange'
	});

	useEffect(() => {
		if(isSubmitSuccessful) {
			reset(defaultPeripheralDetail);
		}
	}, [isSubmitSuccessful]);

	const watchFields = watch();

	const onSubmit = async (data) => {
		if (peripheral.peripheralID === undefined) {
			await handleSave(data);
		} else {
			await handleEdit(data);
		}
	};

	const updatePeripheralSettings = (peripheralSettings, peripheralID) => {
		if (peripheralSettings !== undefined) {
			setValue("settings", peripheralSettings);
		}
	}

	const handleDeletePeripheral = async () => {
		handleDelete(peripheral)
	}

	const getPeripheralFriendlyName = () => {
		for (const element of Object.entries(PERIPHERAL_TYPES)) {
			if (element[1]?.id === peripheral.peripheralTypeID) {
				return element[1]?.friendlyName;
			}
		}
		return "Peripheral";
	}

	const renderConfiguration = () => renderDoorStrike() || renderBNR() || renderGate() || renderProx() || renderCubicTerminal();
	const renderDoorStrike = () => watchFields.peripheralTypeID === PERIPHERAL_TYPES.DOORSTRIKE.id;
	const renderBNR = () => watchFields.peripheralTypeID === PERIPHERAL_TYPES.BNR.id && peripheral.isActive && canConfigureCashUnit;
	const renderGate = () => watchFields.peripheralTypeID === PERIPHERAL_TYPES.GATE.id;
	const renderProx = () => watchFields.peripheralTypeID === PERIPHERAL_TYPES.PROXCARDREADER.id && isReaderConfigurationEnabled;
	const renderCubicTerminal = () => watchFields.peripheralTypeID === PERIPHERAL_TYPES.CUBICTERMINAL.id;

	const setCreditCardGateways = (gateways, selectedCreditCardGateway) => {
		gateways.forEach(element => {
			element.active = element.creditCardGatewayID?.toLowerCase() === selectedCreditCardGateway.toLowerCase();
			element.setting = element.creditCardGatewayID;
		});
		setGateways(gateways);
	}

	useEffect(() => {
		if (watchFields.peripheralTypeID === PERIPHERAL_TYPES.GATE.id) {
			if (Object.keys(peripheral.settings ?? {}).length > 0) {
				setValue("settings", peripheral.settings);
			} else {
				setValue("settings", {
					"gateType": "1"
				});
			}
		} else if (watchFields.peripheralTypeID === PERIPHERAL_TYPES.DOORSTRIKE.id) {
			setValue("settings", {
				ioMappingID: peripheral?.settings?.ioMappingID ?? "0",
				friendlyName: peripheral?.settings?.friendlyName ?? "None",
				vendStrikeDuration: peripheral?.settings?.vendStrikeDuration ?? 5000,
			});
		} else if (watchFields.peripheralTypeID === PERIPHERAL_TYPES.PROXCARDREADER.id) {
			setValue("settings", {
				interfaceBoardType: peripheral?.settings?.interfaceBoardType ?? 1,
			});
		} else if (watchFields.peripheralTypeID === PERIPHERAL_TYPES.CREDITCARDREADER.id) {
			const selectedCreditCardGateway = peripheral?.settings?.creditCardGateway ?? "Windcave";
			const deviceTimeout = peripheral?.settings?.transactionTimeout ?? 185;
			setValue("settings", {
				creditCardGateway: selectedCreditCardGateway,
				transactionTimeout: deviceTimeout
			});
			(async() => {
				try {
				  const result = await getCreditCardGateways();
				  if (result.data.length > 0) {
					setCreditCardGateways(result.data, selectedCreditCardGateway);
				  } else {
					enqueueSnackbar("Could not find any gateways to display!", {
					  variant: "error",
					  tag: "FailedToFindGatewaysToDisplay"
					});
				  }
				} catch (err) {
				  enqueueSnackbar("An error occurred while fetching gateways!", {
					variant: "error",
					tag: "FailedToFetchGateways"
				  });
				}
			})();
		} else if (watchFields.peripheralTypeID === PERIPHERAL_TYPES.CUBICTERMINAL.id) {
			setValue("settings", {
				hostID: peripheral?.settings?.hostID ?? '',
				ipAddress: peripheral?.settings?.ipAddress ?? '',
				port: peripheral?.settings?.port ?? '',
				rateID: peripheral?.settings?.rateID ?? ''
			});
		} else {
			setValue("settings", {});
		}
	}, [watchFields.peripheralTypeID])
	
	const handleSelectChange = item => {
		updatePeripheralSettings({creditCardGateway: item.name});
	};

	return (
		<div className={classes.peripheralsDrawer} data-peripheral-id={peripheral.peripheralID === undefined ? "new" : peripheral.peripheralID}>
			<Box className={classes.peripheralBox}>
				<Grid className={clsx([classes.headerContainer])}>
					<Grid container className={clsx([classes.headerContainerSpacing])}>
						<Grid item xs={8} lg={10}>
							<Typography 
								className={clsx([classes.title])}
								component="h2"
								variant="h4"
								color="primary"
							>
								{peripheral.peripheralID === undefined ? "Add a Peripheral" : "Peripheral Configuration"}
							</Typography>
						</Grid>
						{(peripheral.peripheralID) && (
							<Grid item xs={4} lg={2}>
								<Button
									className={clsx("deleteBtn", classes.btnDelete)}
									name="delete"
									variant="contained"
									color="secondary"
									onClick={() => handleDeletePeripheral()}
									disabled={isSubmitting}
									data-testid="deleteBtn"
								>
									Delete
								</Button>
							</Grid>
						)}
					</Grid>
				</Grid>
				<Divider className={classes.peripheralDividerBottom} />
				<Grid className={clsx([classes.mainContainer])}>
					<Grid container>
						<Grid item xs={12} lg={12} className={clsx([classes.drawerItems])}>
							{peripheral.peripheralID === undefined
								? (
									<Controller
										name="peripheralTypeID"
										control={control}
										render={({ field }) => (
											<FormControl fullWidth role="peripheral-type">
												<TextField
													id="peripheralType"
													label="Peripheral Type"
													select
													{...field}
													value={field?.value || ''}
													data-value={field?.value}
													className={clsx("peripheralType", classes.flexInput)}
													variant="outlined"
													error={!!errors.peripheralTypeID}
													helperText={errors.peripheralTypeID && errors.peripheralTypeID.message}
													disabled={isUndefined(peripheral.peripheralID) ? false : true}
													required={true}
													onChange={(e) => {
														field.onChange(e);
														setSelectedPeripheralID(e.target.value);
													}}
												>
													{Object.entries(PERIPHERAL_TYPES)?.map((elment) => {
														return !(elment[1].id === PERIPHERAL_TYPES.CREDITCARDREADER.id && isCCTerminalConfigured) &&
														!(elment[1].id === PERIPHERAL_TYPES.CUBICTERMINAL.id && (isCubicTerminalConfigured || !isCubicTerminalEnabled)) &&
															<MenuItem
																key={elment[1].id}
																id={elment[1].id}
																value={elment[1].id}
																name="peripheralTypeList"
																data-name={elment[1].friendlyName}
															>
																{elment[1].friendlyName}
															</MenuItem>	
													})}
												</TextField>
											</FormControl>
										)}
									/>)
								: (
									<Typography className={clsx([classes.ConfigurationTitle])}>
										{getPeripheralFriendlyName()}
									</Typography>
								)
							}
						</Grid>
					</Grid>
					{selectedPeripheralID === PERIPHERAL_TYPES.CREDITCARDREADER.id &&
						<Grid container>
							<Grid item xs={12} lg={12} className={clsx([classes.drawerItems, classes.gatewayDropDown])}>
								<GatewayDropDown
									onChange={handleSelectChange}
									gateways={gateways} //why are these the same?
									gatewaySettings={gateways} //why are these the same?
								/>
							</Grid>
							<Grid item xs={12} lg={12} className={clsx([classes.drawerItems])}>
								<Controller
									name="settings.transactionTimeout"
									control={control}
									render={({ field }) => (
										<FormControl fullWidth role="peripheral-timeout">
											<Tooltip title="The timeout before voiding a transaction when a credit card is inserted into the credit card terminal and not pulled out of the reader" arrow>
												<TextField
													{...field}
													id="timeout"
													className={clsx("timeout", classes.flexInput)}
													label="Device Timeout (seconds)"
													variant="outlined"
													type="number"
													error={!!errors.settings?.transactionTimeout}
													helperText={errors.settings?.transactionTimeout && errors.settings.transactionTimeout.message}
													onChange={(e) => {
														const value = e.target.value;
														field.onChange(value === '' ? '' : parseInt(value, 10));
													}}
												/>
											</Tooltip>
										</FormControl>
									)}
								/>
							</Grid>
							<Grid item xs={12} lg={12} className={clsx([classes.drawerItems])}>
								<Controller
									name="ccdeviceid"
									control={control}
									render={({ field }) => (
										<FormControl fullWidth role="peripheral-ccdeviceid">
											<TextField
												{...field}
												id="ccdeviceid"
												className={clsx("ccdeviceid", classes.flexInput)}
												label="Credit Card Device ID"
												variant="outlined"
												error={!!errors.ccdeviceid}
												helperText={errors.ccdeviceid && errors.ccdeviceid.message}
											/>
										</FormControl>
									)}
								/>
							</Grid>
						</Grid>
					}
					<Grid container>
						{peripheral.peripheralTypeID === PERIPHERAL_TYPES.CREDITCARDREADER.id ? (
							<>
							<Grid item xs={12} lg={12} className={clsx([classes.drawerItems, classes.gatewayDropDown])}>
								<Controller
									name="settings.creditCardGateway"
									control={control}
									render={({ field }) => {
										const isSelectDisabled = (deviceSettings?.deviceMode === DEVICE_MODE.ENTRY || deviceSettings?.deviceMode === DEVICE_MODE.EXIT) && deviceSettings.armingLoop === LOOP_STATUS.Active
										return (
										<FormControl fullWidth role="credit-card-gateway" >
											<Tooltip title={isSelectDisabled? "Cannot update the gateway if device is armed" : ""} placement="top-end">
											<TextField
												disabled={isSelectDisabled}
												id="creditCardGateway"
												label="Gateway"
												data-id="GatewaySelect"
												select
												{...field}
												value={field?.value}
												data-value={field?.value}
												className={clsx("peripheralType", classes.flexInput)}
												variant="outlined"
												error={!!errors.settings?.creditCardGateway}
												helperText={errors.settings?.creditCardGateway && errors.settings.creditCardGateway.message}
												required={true}
												data-testid="credit-card-dropdown"
											>
												{gateways && gateways.map((row) => (
													<MenuItem
													key={row.creditCardGatewayID}
													value={row.gatewayName}
													id={row.creditCardGatewayID}
													data-testid="creditCardGateway-menu-item"
													name="creditCardGateway"
												>
													{row.gatewayName}
												</MenuItem>
												))}
											</TextField>
											</Tooltip>
										</FormControl>)
									}}
								/>
							</Grid>
							<Grid item xs={12} lg={12} className={clsx([classes.drawerItems])}>
								<Controller
									name="settings.transactionTimeout"
									control={control}
									render={({ field }) => (
										<FormControl fullWidth role="peripheral-timeout">
											<Tooltip title="The timeout before voiding a transaction when a credit card is inserted into the credit card terminal and not pulled out of the reader" arrow>
												<TextField
													{...field}
													id="timeout"
													className={clsx("timeout", classes.flexInput)}
													label="Device Timeout (seconds)"
													variant="outlined"
													type="number"
													error={!!errors.settings?.transactionTimeout}
													helperText={errors.settings?.transactionTimeout && errors.settings.transactionTimeout.message}
													onChange={(e) => {
														const value = e.target.value;
														field.onChange(value === '' ? '' : parseInt(value, 10));
													}}
												/>
											</Tooltip>
										</FormControl>
									)}
								/>
							</Grid>
							<Grid item xs={12} lg={12} className={clsx([classes.drawerItems])}>
								<Controller
									name="ccdeviceid"
									control={control}
									render={({ field }) => (
										<FormControl fullWidth role="peripheral-ccdeviceid">
											<TextField
												{...field}
												id="ccdeviceid"
												className={clsx("ccdeviceid", classes.flexInput)}
												label="Credit Card Device ID"
												variant="outlined"
												error={!!errors.ccdeviceid}
												helperText={errors.ccdeviceid && errors.ccdeviceid.message}
											/>
										</FormControl>
									)}
								/>
							</Grid>
							</>
						) : <></>}
						<Grid item xs={12} lg={12} className={clsx([classes.drawerItems])}>
							<Controller
								name="model"
								control={control}
								render={({ field }) => (
									<FormControl fullWidth role="peripheral-model">
										<TextField
											{...field}
											id="model"
											className={clsx("model", classes.flexInput)}
											label="Model"
											variant="outlined"
											error={!!errors.model}
											helperText={errors.model && errors.model.message}
											required={true}
										/>
									</FormControl>
								)}
							/>
						</Grid>
					</Grid>
					<Grid container>
						<Grid item xs={12} lg={12} className={clsx([classes.drawerItems])}>
							<Controller
								name="serial"
								control={control}
								render={({ field }) => (
									<FormControl fullWidth role="peripheral-serial">
										<TextField
											{...field}
											id="serial"
											className={clsx("serial", classes.flexInput)}
											label="Serial Number"
											variant="outlined"
											error={!!errors.serial}
											helperText={errors.serial && errors.serial.message}
											required={true}
										/>
									</FormControl>
								)}
							/>
						</Grid>
					</Grid>
					{renderConfiguration() && (
						<>
							<Grid className={clsx([classes.configurationContainer, "configuration"])}>
								<Typography className={clsx([classes.ConfigurationTitle, "title"])}>
									Configuration
								</Typography>
							</Grid>
							{renderDoorStrike() && (
								<>
									<Grid className={clsx([classes.doorStrike])}>
										<DoorStrikeSettings
											peripheral={peripheral}
											updatePeripheralSettings={updatePeripheralSettings}
										/>
									</Grid>
								</>
							)}
							{renderBNR() && (
								<>
									<Grid className={clsx([classes.bnrConfiguration])}>
										<BNRAccepted entityid={peripheral.entityID} deviceService={deviceService} />
										<Divider className={classes.peripheralDivider} />
										<BNRConfiguration entityid={peripheral.entityID} deviceService={deviceService} />
									</Grid>
								</>
							)}

							{renderGate() && (
								<>
									<Grid className={clsx([classes.gateConfiguration])}>
										<GateSettings peripheral={peripheral}
											updatePeripheralSettings={updatePeripheralSettings} />
									</Grid>
								</>
							)}
							{renderProx() && (
								<>
									<Grid className={clsx([classes.proxReaderConfiguration])}>
										<ProxReaderSettings
											peripheral={peripheral}
											updatePeripheralSettings={updatePeripheralSettings}
										/>
									</Grid>
								</>
							)}
							{renderCubicTerminal() && (
								<>
									<Grid className={clsx([classes.cubicTerminal])}>
										<CubicTerminalSettings
											peripheral={peripheral}
											updatePeripheralSettings={updatePeripheralSettings}
										/>
									</Grid>
								</>
							)}
						</>
					)}
				</Grid>
			</Box>
			<Box className={clsx([classes.drawerDetailConfiguration])}>
				<div>
					<Divider className={classes.peripheralDividerFixed} />
				</div>
				<Button
					className={clsx("cancelBtn", classes.btnControl)}
					name="cancel"
					variant="contained"
					onClick={handleCancel}
					disabled={isSubmitting}
					data-testid="cancelBtn"
				>
					Close
				</Button>
				<Button
					color="primary"
					name="submit"
					type="submit"
					variant="contained"
					className={clsx("saveBtn", classes.btnControl)}
					onClick={handleSubmit(onSubmit)}
					disabled={isSubmitting || !(Object.keys(errors).length === 0 && errors.constructor === Object) || (peripheral.peripheralTypeID === PERIPHERAL_TYPES.CREDITCARDREADER.id && !isDirty)}
					data-testid="saveBtn"
				>
					Save
				</Button>
			</Box>
		</div>
	);
};

PeripheralConfigurationForm.defaultProps = {
	handleSave: () => { },
	handleEdit: () => { },
	handleDelete: () => { },
	handleCancel: () => { },
	peripheral: {}
};

PeripheralConfigurationForm.propTypes = {
	handleSave: PropTypes.func,
	handleEdit: PropTypes.func,
	handleDelete: PropTypes.func,
	handleCancel: PropTypes.func,
};

export default PeripheralConfigurationForm;