import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Switch,
  Tooltip,
  Drawer
} from "@material-ui/core";
import { useEnqueueSnackbar } from "../../hooks/useEnqueueSnackbar";
import _, { cloneDeep } from "lodash";
import PropTypes from "prop-types";
import Title from "../Title";
import { useStyles } from "./styles";
import { PERIPHERAL_TYPES, LOOP_STATUS } from "../../constants";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/pro-regular-svg-icons";
import PeripheralConfigurationForm from "./PeripheralConfigurationForm"
import { useConfirmationDialog } from "../../hooks/useConfirmationDialog";
import usePeripherals from "../../hooks/usePeripherals";
import { useSettingDispatchChange } from "../Forms/Settings/index";
import { FindEntity } from "../../state/slices/entities";
import { useSelector, shallowEqual } from "react-redux";
import {useCoreEntityContext} from "../../hooks/useCoreEntitySlice";
import {selectEntityById} from "../../state/slices/CoreEntity";
import { useFeatureFlag } from "../../hooks/useFeatureFlags"

const PeripheralsEditor = ({ entityID, onClose }) => {
  const classes = useStyles();
  const [peripheralDetail, setPeripheralDetail] = useState();
  const enqueueSnackbar = useEnqueueSnackbar();
  const { showDialog } = useConfirmationDialog();

  const defaultPeripheralDetail = {
    model: "",
    name: "",
    peripheralID: undefined,
    peripheralTypeID: undefined,
    serial: "",
    entityID: entityID,
    settings: {}
  };

  const [drawerState, setDrawerState] = useState(false);
  const { dispatchSetting, dispatchDeleteSetting } = useSettingDispatchChange();
  const useCoreEntitySlice = useCoreEntityContext();
  const isCubicTerminalEnabled = useFeatureFlag('Cubic Terminal');

  const {
    peripherals,
    addPeripheral,
    updatePeripheral,
    removePeripheral,
  } = usePeripherals();

  const deviceSettings = useSelector((state) => { 
    const entity =useCoreEntitySlice ? selectEntityById(state, entityID) :  FindEntity(state.entities?.EntityList ?? [], entityID);
    return {
      ccdeviceid:  _.find(entity?.settings ?? [], { name: "ccdeviceid" })?.value,
      deviceMode: _.find(entity?.settings ?? [], { name: "devicemode" })?.value ??null,
      armingLoop: entity?.state?.Peripherals?.Gate?.ArmingLoop ?? LOOP_STATUS.Unset, //0 unknown, 1 active, 2 inactive
    }
  }, shallowEqual);

  useEffect(() => {
    //if deviceSettings change than rerender component to enable/disable credit card gateway dropdown
    return () => {};
  }, [deviceSettings]);

  const createPeripheral = async (objToUpdate) => {
    try {
      await addPeripheral(objToUpdate);
      if(objToUpdate?.peripheralTypeID === PERIPHERAL_TYPES.CREDITCARDREADER.id) {
        handleUpdateccdeviceID(objToUpdate);
      }
      setDrawerState(false);
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Failed to create peripheral", {
        variant: "error",
        tag: "FailedToCreatePeripheral"
      });
    }
  };

  const savePeripheral = async (objToUpdate) => {
    try {
      await updatePeripheral(objToUpdate);
      if(objToUpdate?.peripheralTypeID === PERIPHERAL_TYPES.CREDITCARDREADER.id && objToUpdate.ccdeviceid !== undefined) {
        handleUpdateccdeviceID(objToUpdate);
      }
      setDrawerState(false);
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Failed to save peripheral", {
        variant: "error",
        tag: "FailedToSavePeripheral"
      });
    }
  };

  const swapPeripheral = async peripheralID => {
    var obj = peripherals.find(p => p.peripheralID === peripheralID);
    if (obj === undefined) return;
    
    let copy = cloneDeep(obj);
    copy.isActive = !copy.isActive;
    await savePeripheral(copy);
  };

  const deletePeripheral = async (objToDelete) => {
    const response = await showDialog({
      title: "Delete the " + PERIPHERAL_TYPES[peripheralDetail.name.toUpperCase()]?.friendlyName,
      message:
        "Deleting this peripheral may make the device behave unpredictably. This feature is meant for when you need to physically replace faulty hardware with a new replacement.",
      buttons: [
        { name: "Yes", color: "primary" },
        { name: "No", color: "secondary" }
      ]
    });

    if (response !== "Yes") return;
    try {
      await removePeripheral(objToDelete);
      if(objToDelete?.peripheralTypeID === PERIPHERAL_TYPES.CREDITCARDREADER.id) {
        await dispatchDeleteSetting(objToDelete.entityID, 'ccdeviceid', objToDelete.ccdeviceid)
      }
      setDrawerState(false);
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Failed to delete peripheral", {
        variant: "error",
        tag: "FailedToDeletePeripheral"
      });
    }
  };

  const handleSelected = async (peripheral) => {
    setDrawerState(true);
    const ccdeviceid = deviceSettings.ccdeviceid;
    const peripheralDetail = {...peripheral, ccdeviceid};
    setPeripheralDetail(peripheralDetail);
  };

  const onAddClick = () => {
    setDrawerState(true);
    setPeripheralDetail(defaultPeripheralDetail);
  }

  const closeDrawer = () => {
    setDrawerState(false);
  };

  const handleUpdateccdeviceID = async (peripheral) => {
      await dispatchSetting(peripheral.entityID, 'ccdeviceid', peripheral.ccdeviceid);
  }

  const orderPeripheralList = () => {
    return peripherals?.sort((a, b) => {
      if (a.peripheralTypeID < b.peripheralTypeID) {
        return -1;
      }
      if (a.peripheralTypeID > b.peripheralTypeID) {
        return 1;
      }
      if (a.peripheralID < b.peripheralID) {
        return -1;
      }
      if (a.peripheralID > b.peripheralID) {
        return 1;
      }
      return 0;
    });
  };

  return (
    <div className={classes.peripheralsDrawer}>
      <Box className={classes.peripheralBox}>
        <Grid container item xs={12} sm={12} className={clsx([classes.headerContainerSpacing])}>
          <Grid item xs={8} sm={10}>
            <Title>Peripherals</Title>
          </Grid>
          <Grid item xs={4} sm={2}>
            <Button
              className={clsx("btn-add", classes.addButton)}
              data-id="add-btn"
              data-testid="add-btn"
              variant="contained"
              color="primary"
              onClick={onAddClick}
            >
              {"Add"}
            </Button>
          </Grid>
        </Grid>
        <Divider className={classes.peripheralDivider} />
        <Grid container className={classes.peripheralGrid}>
          {peripherals && orderPeripheralList().map((row) => (
            PERIPHERAL_TYPES[row.name.toUpperCase()] &&
            !(row.peripheralTypeID === PERIPHERAL_TYPES.CUBICTERMINAL.id &&  !isCubicTerminalEnabled) &&
            <Grid container item xs={12} lg={12} className={clsx([classes.peripheralLine, "peripheral", row.name])} key={row.peripheralID} data-id={row.peripheralID}>
              <Grid
                item
                xs={8}
                lg={8}
                className={clsx([
                  classes.peripheralLabel
                ])}
              >
                <Grid className={"type"} item xs={12} lg={12}>
                  {PERIPHERAL_TYPES[row.name.toUpperCase()]?.friendlyName ?? row.name}
                </Grid>
                <Grid container item xs={12} lg={12} spacing={2}>
                  <Grid item xs={6} lg={6} className={clsx([classes.peripheralDetail, "model"])} >
                    Model : {row.model ? row.model : "None"}
                  </Grid>
                  <Grid item xs={6} lg={6} className={clsx([classes.peripheralDetail, "serial-number"])}>
                    S/N : {row.serial ? row.serial : "None"}
                  </Grid>
                </Grid>
              </Grid>
              <Grid data-tag="activeGrid" item xs={2} lg={2} className={classes.peripheralEnabled} >
                <Switch
                  data-name={
                    PERIPHERAL_TYPES[row.name.toUpperCase()]
                      ?.friendlyName ?? row.name
                  }
                  className={clsx([
                    "peripheral-switch",
                    row.isActive ? "enabled" : "disabled",
                    classes.peripheralSwitch
                  ])}
                  color="primary"
                  name="isActive"
                  checked={row.isActive}
                  data-checked={row.isActive}
                  onChange={() => {
                    swapPeripheral(row.peripheralID);
                  }}
                />
              </Grid>
              <Grid data-tag="activeGrid" item xs={2} lg={2}>
                <div className={clsx(classes.peripheralIcon)}>
                  <Tooltip title={PERIPHERAL_TYPES[row.name.toUpperCase()]?.friendlyName + " settings"}>
                    <IconButton
                      data-testid="icon-button"
                      className={clsx("view-settings-btn")}
                      color="primary"
                      variant="contained"
                      onClick={(e) =>
                        handleSelected(row)
                      }
                    >
                      <FontAwesomeIcon icon={faCog} />
                    </IconButton>
                  </Tooltip>
                </div>
              </Grid>
            </Grid>
            // </>
          )
          )}
        </Grid>
      </Box>
      <Box className={clsx([classes.drawerDetailConfiguration])}>
        <div>
          <Divider className={classes.peripheralDividerFixed} />
        </div>
        <Button
          className={clsx("peripheral-cancel", classes.cancelBtn)}
          variant="contained"
          data-id="closeBtn"
          onClick={onClose}
        >
          Close
        </Button>
      </Box>
      <Drawer
        className={clsx("drawer", "peripheral-config")}
        anchor="right"
        open={drawerState}
        classes={{
          paper: classes.drawerDetailConfiguration,
        }}
        onClose={closeDrawer}
      >
        <PeripheralConfigurationForm
          handleSave={createPeripheral}
          handleEdit={savePeripheral}
          handleDelete={deletePeripheral}
          handleCancel={closeDrawer}
          peripheral={peripheralDetail}
          isCCTerminalConfigured={peripherals && !!peripherals.find((peripheral) => peripheral.peripheralTypeID === PERIPHERAL_TYPES.CREDITCARDREADER.id)}
          isCubicTerminalConfigured={peripherals && !!peripherals.find((peripheral) => peripheral.peripheralTypeID === PERIPHERAL_TYPES.CUBICTERMINAL.id)}
          deviceSettings={deviceSettings}
        />
      </Drawer>
    </div>
  );
}

PeripheralsEditor.defaultProps = {
  entityID: null,
  onClose: () => { },
};

PeripheralsEditor.propTypes = {
  entityID: PropTypes.string,
  onClose: PropTypes.func,
};

export default PeripheralsEditor;