import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
  root: {},
  propertyDialog: {},
  content: { maxHeight: "500px" },
  propertyList: {},
  searchIcon: { marginRight: "5px" },
  title: { paddingBottom: "0px" },
  selected: {
    backgroundColor: theme.palette.background.paper,
    color: "white",
    fontWeight: 600
  },
  facilityParentSpacing: { marginLeft: 10, marginTop: 20 },
  facilityParentIconSpacing: { marginRight: 10 },
  searchContainer: {
    position: "sticky",
    top: 0,
    backgroundColor: `${theme.palette.background.paper} !important`,
    zIndex: 1000,
  },
  nestedFacility: {
    paddingLeft: `80px !important`,
    '& .MuiListItemIcon-root': {
      minWidth: "30px"
    }
  },
  facilityGroupSpacing: {
    paddingLeft: "40px !important",
    margin: "5px 0 10px 0",
  },
  facilityGroupIconContainer: {
    display: "inline-block",
    minWidth: "30px"
  },
  facilityGroupLabel: {
    display: "inline-block",
    fontWeight: "bold",
    fontSize: "1rem",
  },
  standAloneFacility: {
    paddingLeft: `40px !important`,
    '& .MuiListItemIcon-root': {
      minWidth: "30px"
    }
  },
  searchHighlight: {
    backgroundColor: "yellow"
  },
}));
